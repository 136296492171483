import React from 'react'

import Header from  './header'
import Intro from './intro'
import About from './about'
import Project from './projects'
import Copyright from './copyright'

import layoutStyles from '../styles/layout.module.scss'

export default function layout(props) {
    return (
        <div className={layoutStyles.header} >
            <section className={layoutStyles.hero}>
                <div>
                    <div className={layoutStyles.hero_left_bottom}>&nbsp;</div>
                    <div className={layoutStyles.hero_right_bottom}>&nbsp;</div>
                    <div className={layoutStyles.hero_right_top}>&nbsp;</div>
                </div>
                <Header></Header>
                <Intro></Intro>
            </section>
            <section className={layoutStyles.about} id="about">
                 <div>
                    <div className={layoutStyles.about_left_top}>&nbsp;</div>
                    <div className={layoutStyles.about_right_bottom}>&nbsp;</div>
                    <div className={layoutStyles.about_left_bottom}>&nbsp;</div>
                </div>
                <About></About>
            </section>
            <section className={layoutStyles.projects} id="projects">
                <div>
                    <div className={layoutStyles.project_bottom_light}>&nbsp;</div>
                    {/* <div className={layoutStyles.project_bottom_dark}>&nbsp;</div> */}
                    <div className={layoutStyles.project_left_top}>&nbsp;</div>
                </div>
                <Project></Project>
            </section>
            <section className={layoutStyles.footer} id="contact">
                {/* <div className={layoutStyles.footer__wrapper}>
                    <p>Copyright</p>
                </div> */}
                <Copyright></Copyright>
            </section>
            {props.children}
        </div>
    )
}
