import React from 'react'

import aboutStyles from '../styles/about.module.scss'

export default class about extends React.Component {
    
    render(){
        return (
            <div className={aboutStyles.about} id='about'>
                <h3 className={aboutStyles.about__title}>About me</h3>
                <p className={aboutStyles.about__text}>An enthusiastic web developer looking for a challenging work in new and different environments.</p>
                <p className={aboutStyles.about__text}>I always have a passion for robotics since I was young, so I decided to study in Computer Science. After graduated, I had worked with a rapid-grow start up company where I implemented software using Salesforce for 2 years. In 2017, I continue to study in more generic field which is Web and Mobile App Design and Development at Langara College, Vancouver, BC, Canada. During the course, I have gained a lot of experience and valuable skills such as JavaScript, WordPress together with embedded systems, and cloud computing.</p>
            </div>
        )
    }
}
