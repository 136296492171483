import React from "react"
import { Helmet } from 'react-helmet'

import Layout from "../components/layout"
import "../styles/index.scss"

const IndexPage = () => (
  <div>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Noppawit Hansompob</title>
    </Helmet>
    <Layout>
    </Layout>
  </div>
  
)

export default IndexPage
