import React from 'react'

import Social from './socials'

import introStyles from '../styles/intro.module.scss'

export default function intro() {
    return (
        <div className={introStyles.intro}>
            <div>
                <div className={introStyles.intro__text}>
                    Hello, I'm&nbsp;
                    <h1 className={introStyles.intro__name}>Noppawit Hansompob</h1>
                </div>
            </div>
            <div>
                <div className={introStyles.subTitle}>
                    <h2 className={introStyles.subTitle__text}>Software/Web Developer</h2>

                    <ul className={introStyles.subTitle__list}>
                        <li className={introStyles.subTitle__list__item}><span role="img" aria-label="pizza">🍕</span></li>
                        <li className={introStyles.subTitle__list__item}><span role="img" aria-label="icecream">🍦</span></li>
                        <li className={introStyles.subTitle__list__item}><span role="img" aria-label="car">🚗</span></li>
                        <li className={introStyles.subTitle__list__item}><span role="img" aria-label="sakura">🌸</span></li>
                    </ul>
                </div>
            </div>
            <div className={introStyles.socials}>
                <Social></Social>
            </div>
        </div>
    )
}
