import React from 'react'

import Social from './socials'

import copyrightStyles from '../styles/copyright.module.scss'

export default function copyright() {
    let date = new Date();
    let currentYear = date.getFullYear();
    return (
        <div className={copyrightStyles.copyright}>
            <Social></Social>
            <p className={copyrightStyles.copyright__text}>Build with <a href="https://www.gatsbyjs.org/">Gatsby</a> by Noppawit Hansompob &copy; {currentYear}</p>
        </div>
    )
}
