import React from 'react'

import { FaShareSquare } from 'react-icons/fa'

import projectItemStyles from '../styles/projectItem.module.scss'

export default function project_item(props) {
    console.log(props);
    let {title,description,link,tags} = props.project;
    console.log(title);
    console.log(description);
    console.log(link);
    console.log(tags);
    return (
        <div className={projectItemStyles.projectItem}>
            <h4 className={projectItemStyles.projectItem__title}>{title}</h4>
            <p className={projectItemStyles.projectItem__description}>{description}</p>
            <div className={projectItemStyles.projectItem__tag_wrapper}>
                {tags.map((tag_item, index) => {
                    return <a href="/" key={index} className={projectItemStyles.projectItem__tag}>{tag_item}</a>
                })}
            </div>
            <a href={link} target="_blank" rel="noopener noreferrer" className={projectItemStyles.projectItem__arrow}><FaShareSquare></FaShareSquare></a>
        </div>
    )
}
