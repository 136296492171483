import React from 'react'
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa'

import socialStyles from '../styles/socials.module.scss'

export default function socials() {
    return (
        <div>
            <a href="/" className={socialStyles.link}><FaEnvelope></FaEnvelope></a>
            <a href="https://github.com/NTHB" className={socialStyles.link}><FaGithub></FaGithub></a>
            <a href="https://www.linkedin.com/in/noppawith/" className={socialStyles.link}><FaLinkedin></FaLinkedin></a>
        </div>
    )
}
