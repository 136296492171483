import React from 'react'

import Project from './project_item'

import projectStyles from '../styles/project.module.scss'

let project_data = [
    {
        title: "Weather forecast App",
        description: "Web application for providing weather forecast using openweathermap api",
        link: "https://github.com/NTHB/weather-app",
        tags: ["React.js","CSS","OpenWeatherMapAPI","JavaScript"]
    },
    {
        title: "React News App",
        description: "A React web application for searching news via news api",
        link: "https://nthb.github.io/news-app/",
        tags: ["React.js","Material-UI","NewsAPI","JavaScript"]
    },
    {
        title: "Natour",
        description: "Website showcase for CSS animation and decoration",
        link: "https://nthb.github.io/natour-project/",
        tags: ["HTML","CSS","SASS"]
    },
    {
        title: "Hundle Project",
        description: "the completed version of challenge from frontendmentor.io",
        link: "https://nthb.github.io/huddle-project/",
        tags: ["React.js","Material-UI","NewsAPI","JavaScript"]
    },
    {
        title: "Tindog",
        description: "Showcase of using Bootstrap4 library",
        link: "https://nthb.github.io/tindog/",
        tags: ["HTML","CSS","SASS","Bootstrap4"]
    },
    {
        title: "Hour Tech",
        description: "A platform for connecting people who have tech problems (Lead iOS developer)",
        link: "https://hourtech.ca/",
        tags: ["React.js","Swift","Java","Firebase","FireCloud","iOS","Android"]
    },
    {
        title: "Rentalmoose",
        description: "Startup initiative to create a platform which let people find a rental property (Back-End assistant)",
        link: "https://www.therentalmoose.com/",
        tags: ["React.js","D3.js","Django","Python","Flask","JavaScript"]
    },
    {
        title: "Style.me",
        description: "A mobile application for suggesting outfit with existing clothes",
        link: "https://raw.githubusercontent.com/NTHB/portfolio/master/resources/images/project/styleme-project.jpg",
        tags: ["HTML","CSS","JavaScript","Cordova","Mobile"]
    },
    {
        title: "Movie Service App",
        description: "A React Native application for searching movie from imdb database",
        link: "https://github.com/NTHB/movie-app",
        tags: ["React.js","Material-UI","React Native","imdbAPI","Axios"]
    },
    {
        title: "To do App",
        description: "A React web application for listing and managing tasks, have a capability to drag and drop task",
        link: "https://github.com/NTHB/todo-react",
        tags: ["React.js","Bootstrap4","jQuery","Firebase","JavaScript"]
    }
];

export default class projects extends React.Component {
    state = {
        project : project_data
    }
    render() {
        return (
            <div className={projectStyles.project}>
             <h3 className={projectStyles.project__title}>Projects</h3>
             <div className={projectStyles.project__project_item_wrapper}>
                {
                    this.state.project.map((eachProject,index) => {
                        return <Project project={eachProject} key={index}></Project>
                    })
                }
             </div>
        </div>
        )
    }   

}
