import React from 'react'

import headerStyles from '../styles/header.module.scss'



export default function Header() {

    if (typeof window !== 'undefined') {
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function() {
            var currentScrollPos = window.pageYOffset;
            // console.log(`currentScroll bf >> ${currentScrollPos}`);

            if (prevScrollpos > currentScrollPos || currentScrollPos <= 0 ) {
                document.getElementById("navbar").style.top = "0";
                document.getElementById("navbar").style.backgroundColor ="#3a4e7a";
                document.getElementById("logo").style.color ="#f1f1f0";
            } else {
                document.getElementById("navbar").style.top = "-10vh";
            }
            prevScrollpos = currentScrollPos;

            // console.log(`currentScroll at >> ${currentScrollPos}`);
            if (currentScrollPos <= 0) {
                document.getElementById("navbar").style.backgroundColor ="transparent";
                document.getElementById("logo").style.color ="#003D82";
            }
        }
    }
    
    return (
        <header className={headerStyles.header} id="navbar">
            <div className={headerStyles.container}>
                <h2 className={headerStyles.title} ><a href="/" id="logo">NTHB</a></h2>
                <nav className={headerStyles.nav}>
                    <ul>
                        <li>
                            <a href="#about" className={headerStyles.link}>About</a>
                        </li>
                        <li>
                            <a href="#projects" className={headerStyles.link}>Projects</a>
                        </li>
                        <li>
                            <a href="#contact" className={headerStyles.link}>Contact</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}
